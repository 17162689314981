.job-note-container {
  margin-top: 15px;

  .inner-section {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 10px 0;
    font-size: inherit;
    border-color: #d4e0f38a !important;
  }

  .creator-name {
    line-height: 16px;
    margin: 0 10px;
    font-weight: normal;
  }

  .note-text-container {
    flex-grow: 1;
    min-height: 60px;
    padding: 10px;
    border: solid 2px #d4e0f38a;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: column;
    text-align: left;
  }
}