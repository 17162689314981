$table-header: transparent;
$table-header-border: #9FC0F3;
$table-border: #D4E0F3;
$row-bg: #f4f2f1;

div {
  box-sizing: border-box;
}

.table-container {
  display: block;
  padding: 0;
  margin-top: 30px;
}

.flag-icon {
  margin-right: 0.1em;
}

.flex-table {
  display: flex;
  flex-flow: row wrap;
  min-width: 100%;
}

.flex-table.header {
  border-bottom: solid 1px $table-border;
  font-weight: 500;
}

.flex-table.row {
  margin: 0 !important;
  border-bottom: solid 1px $table-border;
  border-top: solid 1px $table-border;
}

.payroll {
  overflow-x: hidden;
  height: 100%;
  overflow-y: auto;

  .payroll-employees,
  .payroll-time,
  .payroll-jobs {
    z-index: 2;

    span.clickable {
      border-radius: 15px;
      cursor: pointer;
      padding: 6px 12px;
    }

    .flex-table.row:hover {
      span.clickable {
        background: #EAF2FF;
      }
    }
  }

  .payroll-time {
    .flex-table.header {
      .flex-row {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .payroll-jobs {
    flex-grow: 1;

    .flex-table.header .flex-row {
      padding: 8px;
    }

    .fas,
    .far {
      margin-right: 5px;
    }
  }

  .flex-row {
    padding: 15px 0;
  }

  .flex-row:not(.first):not(.sm) {
    text-align: center;
  }

}

.flex-row {
  text-align: left;
  padding: 0.5em 0.5em;
  padding-left: 0;
}

.flex-row.first {
  font-weight: 500;
}

.jobs-row {
  display: flex;
  flex-grow: 1;

  .flex-row {
    flex-grow: 1;
  }
}

.controls-container {
  padding-left: 15px;
}

.controls-container h4 {
  margin: 0;
  margin-right: 25px;
  font-weight: 500;
}

.flex-row.sm {
  word-break: break-all;
  text-align: center;
}

.flex-table.header .flex-row {
  padding: 8px 0;
}

.payroll-jobs {
  overflow-x: scroll;
  scroll-behavior: smooth;

  .flex-table {
    display: inline-flex;
    border-radius: 0 !important;
  }
}

.payroll-jobs::-webkit-scrollbar {
  display: none;
}

.employee-job-hours {
  width: 170px;
  flex-grow: 1;
  flex-shrink: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.employee-job-hours-link {
  width: 170px;
  flex-grow: 1;
  flex-shrink: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: underline;
}

.arrow {
  background: #3D7FE5;
  min-width: 35px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 37px;
  cursor: pointer;
}

.arrow span {
  display: table-cell;
  vertical-align: middle;
}

.jobs-section-container {
  float: left;
  overflow: hidden;
  padding: 0;
  height: 100%;
  display: inline-flex;
}

.white-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 8px 0;
  z-index: 1;
  color: white;
}

.no-jobs-alert {
  margin-top: 25px;
  font-size: 16px;
}