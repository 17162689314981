
  .job-date-picker {
    font-size: inherit;
  }
  
  .job-date-picker .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #4a90e21f !important;
    color: #4a90e2;
  }

  .job-date-picker .DayPicker-Day {
    border-radius: 0 !important;
  }

  .job-date-picker .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .job-date-picker .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .reset-btn, 
  .done-btn {
    font-size: 12px;
    padding: 2px 5px;
  }

  .done-btn {
    margin-left: 10px;
  }

  .calendar-container {
    width: 100%;
    padding: 20px 20px 0 20px;
    border: 1px solid #d4e0f3;
    background: #F6FAFF;
    border-radius: 5px;
  }
  