// Fonts
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
    "primary": #3D7FE5,
);


@media print {
    .Toastify__toast {
        display: none !important;
    }
}

.Toastify__toast {
    font-family: 'Rubik', sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-weight: 500;
}

.Toastify__toast--success {
    background-color: #28a745 !important;
}

.Toastify__toast--danger {
    background-color: #dc3545 !important;
}

.react-bootstrap-table table {
    table-layout: fixed; }

.table-fixed {
    table-layout: fixed;
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";