.menu-container {
    background: #EAF2FF;
    color: #737F99;
    width: 220px;

    .fas:not(.fa-chevron-right) {
        margin-right: 14px;
    }

    .fa-chevron-right {
        margin-right: 0;
        font-size: 12px !important;
        margin: 0 8px 0 15px;
        padding-top: 3px;
        color: #EAF2FF;
    }

    .fas {
        font-size: 18px;
        line-height: 38px;
    }

    .menu-item {
        line-height: 38px;
    }

    .menu-item.active {
        color: #3D7FE5;

        .fa-chevron-right {
            color: inherit;
        }
    }
}

.sidebar-expenses,
.sidebar-men-hours {
    font-size: 14px;
    flex-direction: column;
    display: flex;

    .expenses-title,
    .men-hours-title {
        padding-bottom: 5px;
        border-bottom: solid 1px #d4e0f38a;
        font-weight: 500;

        span:last-child {
            margin-left: 10px;
            color: #737F99;
        }
    }

    .expense-container,
    .men-hours-container {
        color: #737F99;
        display: inline-flex;
        margin-top: 10px;
        justify-content: space-between;
    }

    .expense-container {
        .amount {
            color: #dc3545;
        }
    }
}