.login-modal {
    .modal-content {
        padding: 4px 20px 24px;
    }

    .modal-title {
        font-size: 18px;
        font-weight: 500;
    }

    .modal-body {
        padding-bottom: 0;
    }

    .login-form {
        font-size: 16px;
        line-height: 18px;

        .form-group {
            overflow: hidden;
        }

        .form-label {
            float: left;
            color: gray;
            font-size: 12px;
        }

        input {
            border-radius: 18px;
        }

        .sign-in-btn {
            .spinner-border {
                color: white !important;
            }
        }
    }
}