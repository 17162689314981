.new-employee-btn {
  position: fixed;
  right: 24px;
  border-radius: 100%;
  border: none;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 10px;
  margin-top: 15px;
  outline: none;
  z-index: 100;
}

.employees-title {
  line-height: 35px;
}

.employees-search input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.employees-search .input-group-text {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
  background-color: #3D7FE5;
}

.status-select {
  border: none;
  background: none;
  margin: 0 auto;
  font-family: inherit;
  font-weight: 500;
}

.new-employee-btn {
  position: fixed;
  right: 24px;
  border-radius: 100%;
  border: none;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 10px;
  margin-top: 15px;
  outline: none;
}

.error-modal {
  .modal-header {
    border-bottom: none;
    background: #dc35452e;
    color: #9c1926;
    font-weight: 500;
  }
}

.employees-list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .flex-table {
    padding: 8px 0;
  }

  .options-container {

    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    margin-right: -1px;
    font-size: 16px;
    border-top: solid 1px #D4E0F3;
    border-bottom: solid .5px #D4E0F3;
    background: #EAF2FF;
    width: 0px;
    display: table;
    padding: 0.5em 0.5em;
    overflow: hidden;
    transition: width 0.5s;

    span {
      display: table-cell;
      vertical-align: middle;
      padding: 8px 0;
      color: #93B8F2;
      line-height: 29px;
      border-left: 1px solid #D4E0F3;
      cursor: pointer;
    }

    span:hover {
      color: #3D7FE5;
    }

    span:first-child {
      border-left: none;
    }

  }

  .flex-table.header {
    padding: 0;
  }

  .flex-table.row {
    border-bottom: solid 1px #d4e0f38a;
    background: white;
  }

  .flex-row {
    line-height: 28px;
    padding-left: 0;
  }

  .inactive i {
    color: #FBF192;
  }

  .inactive {
    background: #FDFADE;
    border: 1px solid #FBF192;
  }

  .active:not(.page-item) i {
    color: #AFFBD7;
  }

  .active:not(.page-item) {
    background: #E6FDF3;
    border: 1px solid #AFFBD7;
  }

  .insured i {
    color: #9EE8FF;
  }

  .insured {
    background: #E1F8FF;
    border: 1px solid #9EE8FF;
  }

  .non-insured i {
    color: #FEB1FB;
  }

  .non-insured {
    background: #FEE7FD;
    border: 1px solid #FEB1FB;
  }
}

.foreman i {
  color: #9EE8FF;
}

.foreman {
  background: #E1F8FF;
  border: 1px solid #9EE8FF;
}

.executive i {
  color: #FEB1FB;
}

.executive {
  background: #FEE7FD;
  border: 1px solid #FEB1FB;
}

.technician i {
  color: #FBF192;
}

.technician {
  background: #FDFADE;
  border: 1px solid #FBF192;
}