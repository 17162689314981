
.job-detail-time-employees {
    overflow-x: scroll;
    scroll-behavior: smooth;
    flex-grow: 1;
    &::-webkit-scrollbar {
        display: none;
    }

    .flex-row {
        line-height: 32px;
        text-indent: 15px;
        width: 180px;
    }

    .times {
        .flex-table.header {
            color: #3D7FE5;
            font-weight: 500;
        }

        .flex-row {
            text-align: center;
            flex-grow: 1;
            flex-shrink: 1;
        }

    }

}