.job-form {
    padding: 15px 0 20px 0;
    font-size: 14px;
    border-top: 1px solid #ddd;

    .text-length {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .form-group:last-child {
        margin-bottom: 0;
    }

    .subtitle {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
    }

    input,
    select {
        border-radius: 5px;
        font-size: inherit;
        transition: border 0.5s;
    }

    div.error,
    input.error,
    textarea.error,
    select.error {
        border: 1px solid #dc3545;
    }

    .calendar-btn.error {
        background-color: #dc3545 !important;
    }

    input.form-control {
        min-height: 38px;
    }

    .calendar-btn {
        cursor: pointer;
    }

}

.job-form:last-child {
    padding-bottom: 0;
}

.crew-form {
    font-size: 14px;
}

.job-modal {

    .select__control {
        transition: border 0.5s;
    }

    .spinner-border {
        color: white;
    }

    .modal-header {
        border-bottom: 0;
        font-weight: 500;
    }

    .modal-header .h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
    }

    .modal-body {
        padding-top: 0;
        padding-bottom: 0;
    }

    .modal-footer {
        border-top: none;
    }

    .tentative-container {
        float: right;
        border: 1px solid #ced4da;
        height: calc(1.5em + .75rem + 2px);
        padding: .1875rem .375rem;
        border-radius: 5px;
        color: #495057;
        display: table;

        span {
            display: table-cell;
            vertical-align: middle;
            padding: .1875rem .375rem;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
        }

        span.active {
            background: #54D197;
            color: white;
        }
    }

}


@media screen and (max-width: 650px) {
    .job-form label {
        margin-top: 7px;
    }
}