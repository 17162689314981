.jobs-list {

    .flex-table {
        padding: 8px 0;
    }

    .options-container {
        position: absolute;
        right: -1px;
        top: 0;
        z-index: -1;
        font-size: 16px;
        border-top: solid 1px #D4E0F3;
        border-bottom: solid .5px #D4E0F3;
        background: #EAF2FF;
        width: 0px;
        display: table;
        padding: 0.5em 0.5em;
        overflow: hidden;
        transition: width 0.5s;

        span {
            display: table-cell;
            vertical-align: middle;
            padding: 8px 0;
            color: #93B8F2;
            line-height: 29px;
            border-left: 1px solid #D4E0F3;
            cursor: pointer;
        }

        span:hover {
            color: #3D7FE5;
        }

        span:first-child {
            border-left: none;
        }

    }

    .flex-table.header {
        padding: 0;
    }

    .flex-table.row {
        border-bottom: solid 1px #d4e0f38a;
        background: white;
    }

    .flex-row {
        line-height: 28px;
    }

    .flex-row {
        padding-left: 0;
    }

}

.jobs-title {
    line-height: 35px;
}

.status-select {
    border: none;
    background: none;
    margin: 0 auto;
    font-family: inherit;
    font-weight: 500;
}

ul.pagination {
    padding-left: 15px;
    padding-right: 15px;
}

ul.pagination li {
    display: inline-block;
}

.na i {
    color: #212529;
}

.na {
    background: #e9ecef;
    border: 1px solid #ced4da;
}

.tentative i {
    color: #9EE8FF;
}

.tentative {
    background: #E1F8FF;
    border: 1px solid #9EE8FF;
}

.scheduled i {
    color: #FEB1FB;
}

.scheduled {
    background: #FEE7FD;
    border: 1px solid #FEB1FB;
}

.danger {
    background: #fcd7da;
    border: 1px solid #f48989;
}

.inprogress i {
    color: #FBF192;
}

.inprogress {
    background: #FDFADE;
    border: 1px solid #FBF192;
}

.completed i,
.received i,
.invoiced i {
    color: #AFFBD7;
}

.completed,
.received,
.invoiced {
    background: #E6FDF3;
    border: 1px solid #AFFBD7;
}

.job-dates {
    font-size: 12px;
    letter-spacing: 1px;
}