.inner-section {
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: solid 1px #d4e0f38a;
    font-size: 12px;

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }
}

.post-contract {
    background: #EAF2FF;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
}

.date.vertical-center-content {
    padding-left: 0;
    padding-right: 25px;
}

.walk-through {
    line-height: 32px;
    margin: 0 10px;
    border-radius: 25px;
    padding: 6px 12px;
    font-weight: 800;

    &.yes {
        background: #53D297;
    }

    &.no {
        background: #dc35452e;
    }
}

.header-photo-modal {
    align-items: baseline;

    * {
        margin-right: 20px;
    }
}

.fa-download {
    cursor: pointer;
}

.team-members {
    line-height: 32px;
    word-break: break-all;
}

.team-member {
    background: #EAF2FF;
    padding: 5px 15px;
    border-radius: 12px;
    line-height: 16px;
    margin-right: 8px;
    color: #737F99;
}

.quantity {
    color: #737F99;
}

.photos-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.modal-photo-img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.note {
    background: #FDF1DB;
    padding: 8px 16px;
    border-radius: 5px;
    margin: 5px;
    font-size: 13px;
}

.notes-container {
    padding-bottom: 15px;
    border-bottom: solid 1px #d4e0f38a;
}

.change-order {
    background: #EAF2FF;
    padding: 20px 15px;
    border-radius: 5px;
    overflow: hidden;
    font-size: 12px;

    .co-title {
        display: block;
        text-align: left;
    }

    .employee-hours {
        color: white;
        display: inline-flex;
        flex-direction: row;
        float: left;
        margin-top: 10px;
        background: transparent;

        .employee-hour-container {
            padding: 0;
            line-height: 32px;
        }

        p {
            color: #737F99;
            margin-bottom: 0;

            span {
                color: #5c6577;
            }
        }
    }

    .left {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
    }

    .right {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
    }
}

.accordion__button.job-detail-accordion-btn {
    &:not(.bg-success) {
        background: #3D7FE5 !important;
    }

    color: white;
}

.crewsize {
    background: #fde1e3;
}

.pc {
    .spinner-border {
        color: white;
        width: 1rem;
        height: 1rem;
    }
}