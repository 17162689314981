.change-order-row {
    border-top: none !important;
    line-height: 34px;
    border-color: #d4e0f38a !important;
    color: #737F99;
    border-radius: 5px;
    justify-content: space-between;
    cursor: pointer;

    .fas {
        line-height: 34px;
    }

    .wo-hours {
        line-height: 48px;
        color: #333;
        font-weight: 500;
    }

    .wo-creator {
        color: #adb5bd;
        margin-left: 10px;
    }

    &:hover {
        background-color: #3D7FE5;
        color: white;
    }
}

.total-hours {
    margin-left: 10px;
}

.change-order-modal {
    font-weight: 500;

    p {
        color: #737F99;
        font-size: 14px;
        word-break: break-all;

        span {
            color: #5c6577;
        }
    }

    .wo-date {
        color: #333;
    }

    .employee-hours {
        color: white;
        display: inline-flex;
        flex-direction: row;
        float: left;
        background: transparent;

        .employee-hour-container {
            line-height: 32px;
            border-radius: 8px;
            display: flex;
        }
    }

    .employee-hours-container {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        margin-bottom: 1rem;

        .fas {
            margin-right: 8px;
        }
    }

    .wo-creator {
        color: #737F99;
        margin-left: 10px;
    }

    .client-signature {
        max-width: 100%;
    }
}