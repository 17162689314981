.employee-payroll-form {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0 20px 0;
    font-size: 14px;
    border-top: 1px solid #ddd;

    .job-id-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
    }

    .form-group {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    .subtitle {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
    }

    input,
    select {
        border-radius: 15px;
        font-size: inherit;
        transition: border 0.5s;
    }

    input.error,
    select.error {
        border: 1px solid #dc3545;
    }

    input[type="radio"] {
        background-color: #dc3545;
    }

    input.success,
    select.success {
        border: 1px solid green;
    }

    .time-container {
        margin-bottom: .5rem;
    }

    .hours-date,
    .hours-date-detail {
        line-height: 32px;
        margin: 0;
        padding: 0 .75rem;
        border: 1px solid #ced4da;
        background-color: #e9ecef;
        color: #3D7FE5;
        text-align: center;
        font-weight: 500;
    }

    .am-pm {
        line-height: 32px;
        margin: 0;
        padding: 0 0;
        border: 1px solid #ced4da;
        background-color: #e9ecef;
        color: #3D7FE5;
        text-align: center;
        font-weight: 500;
        font-size: x-small;
    }

    .am-pm.error {
        border: 1px solid #dc3545;
    }

    .hours-date-detail {
        border-radius: 0 4px 0 0;
        width: 23.64%;
        flex: 0 0 23.64%;
        max-width: 23.64%;
    }

    .no-pre-set {
        flex: 0 0 44.64%;
        max-width: 44.64%;
    }

    .pre-set {
        flex: 0 0 28.5%;
        max-width: 28.5%;
    }

    .form-check-inline {
        display: inline-flex;
        align-items: center;
        padding-left: 0;
        margin-right: 0.2rem;
    }

    .rdt svg:hover {
        cursor: pointer;
    }

    .hours-input {
        height: calc(1.5em + .75rem + 5px);
    }

    .hours-input-with-details {
        border-radius: 0px 10px 0px 0;
    }

    .hours-input:not(:last-child) {
        border-right: 0;
    }

    .accordion__item {
        margin: 0;
    }

    .accordion__button.payroll-detail {
        margin: 3px 0;
    }

    .accordion__button.payroll-detail.payroll-detail.small {
        border-radius: 0 0 10px 10px;
        padding: 7px;
        line-height: 12px;
        margin: 0;
    }
}

.employee-payroll-modal {

    .spinner-border {
        color: white;
    }

    .modal-header {
        border-bottom: 0;
        font-weight: 500;
    }

    .modal-header .h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
    }

    .modal-body {
        padding-top: 0;
        padding-bottom: 0;
    }

    .modal-footer {
        border-top: none;
    }

    .section-title {
        padding-bottom: 10px;
    }

}

.padding-row-form-group {
    padding-left: 14px;
    padding-right: 14px;
}