.job-detail-expenses {
    margin-top: 15px;
    .inner-section {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 10px 0;
        font-size: inherit;
        border-color: #d4e0f38a !important;
    }
    .positive {
        color: #28a745;
    }
    .negative {
        color: #dc3545;
    }
    .day-expenses {
        line-height: 16px;
        margin-right: 15px;
        font-weight: normal;
    }
}

.header-photo-modal {
    align-items: baseline;
    * {
        margin-right: 20px;
    }
}